.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-left: 0px;
  margin-right: 0px;
}
::-webkit-scrollbar {
  width: 5px;
}
@font-face {
  font-family: 'WarblerText-Regular';
  src: local('WarblerText-Regular'), url(./assets/fonts/WarblerText-Regular.otf) format('truetype');
}